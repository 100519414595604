import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Icons = () => {
  const data = [
    {
      icon: require("assets/images/developer/developer-planning-icons-ico-1.svg")
        .default,
      header: "Analiza wstępna",
      description:
        "<ul><li>analiza projektu pod kątem przygotowania do sprzedaży (częsci wspólne, PUM itd.),</li><li>pomoc w przygotowaniu projektu</li></ul>",
    },
    {
      icon: require("assets/images/developer/developer-planning-icons-ico-2.svg")
        .default,
      header: "Analiza sprzedażowa",
      description:
        "<ul><li>analiza konkurencji,</li><li>wybór grupy docelowej,</li><li>przygotowanie strategii marketingowej,</li><li>przygotowanie oferty cenowej,</li><li>etapowanie sprzedaży.</li></ul>",
    },
    {
      icon: require("assets/images/developer/developer-planning-icons-ico-3.svg")
        .default,
      header: "Organizacja procesu",
      description:
        "<ul><li>organizacja procesu sprzedaży,</li><li>projektowanie i produkcja materiałów    marketingowych.</li></ul>",
    },
    {
      icon: require("assets/images/developer/developer-planning-icons-ico-4.svg")
        .default,
      header: "Obsługa posprzedażowa",
      description:
        "<ul><li>bieżąca modyfikacja cenników uzależniona od dynamiki sprzedaży,</li><li>raportowanie,</li><li>kompleksowa obsługa  posprzedażowa klientów.</li></ul>",
    },
    {
      icon: require("assets/images/developer/developer-planning-icons-ico-5.svg")
        .default,
      header: "Sprzedaż",
      description:
        "<ul><li>doradztwo deweloperom na każdym etapie sprzedaży,</li><li>współpraca ze wskazanymi przez dewelopera stronami w zakresie realizacji projektu,</li><li>raportowanie,</li><li>kompleksowa obsługa sprzedażowa</li></ul>",
    },
    {
      icon: require("assets/images/developer/developer-planning-icons-ico-6.svg")
        .default,
      header: "Działania marketingowe",
      description:
        "<ul><li>zaprojektowanie strategii marketingowej,</li><li>bieżące działania promujące inwestycję,</li><li>systematyczna weryfikacja działań i ich konwersji.</li></ul>",
    },
  ]

  return (
    <section className="developer-planning-icons">
      <div className="container-fluid">
        <h2>Działamy kompleksowo</h2>
        <div className="row align-items-center">
          <div className="col-xl-10">
            <div className="row">
              {data.map((item, index) => (
                <div className="col-lg-4 col-sm-6" key={index}>
                  <div
                    className={`developer-planning-icons__item developer-planning-icons__item--${index}`}
                  >
                    <div className="developer-planning-icons__item-icon">
                      <LazyFadeImage>
                        <img src={item.icon} alt="" />
                      </LazyFadeImage>
                    </div>
                    <h4>{item.header}</h4>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-xl-2">
            <img
              className="developer-planning-icons__shape"
              src={
                require("assets/images/developer/developer-planning-icons-shape.svg")
                  .default
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Icons
