import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Hero = () => {
  return (
    <section className="developer-planning-hero">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-5 col-md-6">
            <h2>Sprzedaż inwestycji deweloperskich</h2>
            <p>
              Dobry <strong>projekt deweloperski</strong> to część sukcesu. Jego
              przygotowanie i realizacja to coś, w czym zapewne czujesz się
              najlepiej. Równie ważny jest jednak{" "}
              <strong>proces sprzedaży</strong>, który warunkuje{" "}
              <strong>wysokość Twojego zysku</strong>. O to, zadbamy my.
              Reprezentując dewelopera, stawiamy na{" "}
              <strong>najwyższą jakoś obsługi Klienta premium</strong> przez co
              realizujemy wspólnie wytyczone cele. W efekcie, każdy z nas
              odpowiada za to co potrafi robić najlepiej.{" "}
              <strong>Razem osiągamy sukces</strong>.
            </p>
          </div>
          <div className="col-xl-6 offset-xl-1 col-md-6">
            <LazyFadeImage>
              <div
                className="developer-planning-hero__image"
                style={{
                  backgroundImage: `url('${
                    require("assets/images/developer/developer-planning-hero-image.jpg")
                      .default
                  }')`,
                }}
              />
            </LazyFadeImage>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
