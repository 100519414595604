import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Hero, Icons } from "page_components/developer-planning"

const Developers = () => {
  const title =
    "Zaplanowanie strategii sprzedażowej i aktywna sprzedaż projektów"
  return (
    <Layout
      seo={{
        title: title,
        description:
          "Reprezentując dewelopera, stawiamy na najwyższą jakość obsługi Klienta premium przez co realizujemy wspólnie wytyczone cele i razem osiągamy sukces.",
      }}
    >
      <Breadcrumbs
        title={title}
        parent={{
          title: "Dla deweloperów",
          path: "/dla-deweloperow/",
        }}
      />
      <PageHeader title={title} />
      <Hero />
      <Icons />
    </Layout>
  )
}

export default Developers
